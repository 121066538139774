import React from 'react';

class Config {

    static APIs = {
        baseApiPath: process.env.REACT_APP_BASE_API_PATH,
        propertyApiPath: '/property/{0}/schema/{1}',
        userApiPath: '/user',
        hotelApiPath: '/hotel/{0}',
        statusApiPath: '/status/{0}',
        statusesApiPath: '/status/properties',
        updateCWSApiPath: '/hotel/{0}/cws/{1}',
        reportsApiPath: '/reports',
        runReportsApiPath: '/run',
        runGBTAReportApiPath: '/rungbta',
        publishPropertyApiPath: '/sabre/{0}',
        updateCWSContentApiPath: '/cws/{0}',
        guestTokenCreationPath: '/rez2marketencryption',
        updatePropertyTypePath: '/property/{0}/type/{1}',
    };

    static MANAGE_PERMISSIONS = {
        VIEW: 'view',
        CREATE: 'create',
        EDIT: 'edit',
        DELETE: 'delete',
    };

    static URLs = {
        PORTAL: 'https://portal.hotelrez.co.uk/',
    };

    static LOCAL_DATA_IDS = {
        TOKEN: 'rez2market_jwt_token',
        USER: 'rez2market_user',
        PROPERTY: 'rez2market_property',
        ACTIVE_MENU: 'rez2market_active_menu',
    };

    static REDUX_TYPES = {
        CONTENT_CHANGED: 'CONTENT_CHANGED',
        PROPERTY_UPDATE: 'PROPERTY_UPDATE',
        ACTIVE_MENU_UPDATE: 'ACTIVE_MENU_UPDATE',
        SET_THEME_PROPS: 'SET_THEME_PROPS',
        SET_USER_HOTELS: 'SET_USER_HOTELS',
    };

    static propertyApiPath(propertyGuid, schemaId) {
        return Config.APIs.baseApiPath + Config.APIs.propertyApiPath.replace('{0}', propertyGuid).replace('{1}', schemaId);
    }

    static userApiPath() {
        return Config.APIs.baseApiPath + Config.APIs.userApiPath;
    }

    static hotelApiPath(hotelCode) {
        return Config.APIs.baseApiPath + Config.APIs.hotelApiPath.replace('{0}', hotelCode);
    }

    static statusApiPath(hotelGuid) {
        return Config.APIs.baseApiPath + Config.APIs.statusApiPath.replace('{0}', hotelGuid);
    }

    static updateCWSApiPath(hotelCode, cws) {
        return Config.APIs.baseApiPath + Config.APIs.updateCWSApiPath.replace('{0}', hotelCode).replace('{1}', cws);
    }

    static statusesApiPath() {
        return Config.APIs.baseApiPath + Config.APIs.statusesApiPath;
    }

    static reportsApiPath(id) {
        return Config.APIs.baseApiPath + Config.APIs.reportsApiPath + (id ? `?id=${id}` : '');
    }

    static guestTokenCreationPath() {
        return Config.APIs.baseApiPath + Config.APIs.guestTokenCreationPath;
    }

    static updatePropertyTypePath(propertyGuid, type) {
      console.log([propertyGuid, type]);
        return Config.APIs.baseApiPath + Config.APIs.updatePropertyTypePath.replace('{0}', propertyGuid).replace('{1}', type);
    }

    static putReportApiPath(id) {
        return Config.APIs.baseApiPath + Config.APIs.reportsApiPath + (id ? `/${id}` : '');
    }

    static runReportsApiPath(id) {
        return Config.APIs.baseApiPath + Config.APIs.reportsApiPath + Config.APIs.runReportsApiPath + (id ? `/${id}` : '');
    }

    static runGBTAReportApiPath(hotelCodes) {
        return Config.APIs.baseApiPath + Config.APIs.reportsApiPath + Config.APIs.runGBTAReportApiPath + (hotelCodes ? `/${hotelCodes}` : '');
    }

    static publishPropertyApiPath(propertyId) {
        return Config.APIs.baseApiPath + Config.APIs.publishPropertyApiPath.replace('{0}', propertyId);
    }

    static updateCWSContentApiPath(propertyId) {
        return Config.APIs.baseApiPath + Config.APIs.updateCWSContentApiPath.replace('{0}', propertyId);
    }
}

export default Config;
