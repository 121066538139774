const LocalData = {
    save: (key, value) => {
        localStorage.setItem(key, value);
    },
    load: (key, defaultValue) => {
        return localStorage.getItem(key) ? localStorage.getItem(key) : defaultValue;
    },
    remove: (key) => {
        localStorage.removeItem(key);
    },
};

export default LocalData;
