import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import {SnackbarProvider} from 'notistack';
import LocalData from './helpers/local-data';
import Config from './config';
import './App.css';
import ScrollToTop from './helpers/scroll-top';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse" />
    </div>
);

const TheLayout = React.lazy(() => import('./containers/TheLayout'));

class App extends Component {
    render() {
        function useQuery() {
            return new URLSearchParams(window.location.search);
        }

        let query = useQuery();
        var jwt_token = query.get('jwt_token');

        if (jwt_token) {
            LocalData.save(Config.LOCAL_DATA_IDS.TOKEN, jwt_token);
            window.location.href = '/';
        }

        if (!LocalData.load(Config.LOCAL_DATA_IDS.TOKEN)) {
            window.location.href = Config.URLs.PORTAL;
        }

        return (
            <SnackbarProvider
                maxSnack={ 3 }
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={ 7000 }>
                <HashRouter>
                    <React.Suspense fallback={loading}>
                        <ScrollToTop />
                           <Switch>
                               <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
                           </Switch>
                    </React.Suspense>
                </HashRouter>
            </SnackbarProvider>
        );
    }
}

export default App;
