import LocalData from '../../helpers/local-data';
import Config from '../../config';

let property = LocalData.load(Config.LOCAL_DATA_IDS.PROPERTY);
let activeMenu = LocalData.load(Config.LOCAL_DATA_IDS.ACTIVE_MENU);

const initialState = {
    property: property ? JSON.parse(property) : null,
    activeMenu: activeMenu ? activeMenu : null,
    sidebarShow: 'responsive',
    asideShow: false,
    darkMode: true,
    contentChanged: false,
    userHotels: [],
};

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case Config.REDUX_TYPES.PROPERTY_UPDATE:
            LocalData.save(Config.LOCAL_DATA_IDS.PROPERTY, JSON.stringify(action.payload));

            return {
                ...state,
                ...{type: action.type, property: action.payload},
            };
        case Config.REDUX_TYPES.ACTIVE_MENU_UPDATE:
            LocalData.save(Config.LOCAL_DATA_IDS.ACTIVE_MENU, action.payload);

            return {
                ...state,
                ...{type: action.type, activeMenu: action.payload},
            };
        case Config.REDUX_TYPES.SET_THEME_PROPS:
            return {...state, ...action};
        case Config.REDUX_TYPES.CONTENT_CHANGED:
            return {
                ...state,
                ...{type: action.type, contentChanged: action.payload},
            };
        case Config.REDUX_TYPES.SET_USER_HOTELS:
            return {
                ...state,
                ...{type: action.type, userHotels: action.payload},
            };
        default:
            return state;
    }
};

export default Reducer;
